import React from 'react';
import '../style.scss';
import { AppProps } from 'next/app';
import 'regenerator-runtime/runtime';
import { Store } from 'redux';
import Script from 'next/script';
import { Provider } from 'react-redux';
import { persistStore } from 'redux-persist';
import { PersistGate } from 'redux-persist/integration/react';
import { DefaultSeo } from 'next-seo';
import { clarity } from 'react-microsoft-clarity';
import withReduxStore from '../redux/with-redux-store';
import { MantineProvider, MantineThemeOverride } from '@mantine/core';
import { ApolloProvider } from '@apollo/client';
import { Notifications } from '@mantine/notifications';
import { ModalsProvider } from '@mantine/modals';
import { Inter } from '@next/font/google';
import NextNProgress from 'nextjs-progressbar';
import { useApollo } from '../lib/apollo-client';
import FeedbackWidget from '../components/FeedbackWidget';
import CheckToken from '../components/CheckToken';
import VersionContext from '../contexts/VersionContext';
import * as Types from '../declarations';
import { withVersion } from '../lib/withVersion';
interface Props extends AppProps {
  // Component: FC
  // pageProps: any
  reduxStore: Store;
  // version: Types.VERSION
}
const interFont = Inter({
  weight: ['400'],
  subsets: ['latin'],
  display: 'swap',
  variable: '--font-inter'
});
const trekkingColours = ['#0e4868', '#105279', '#135b89', '#15659a', '#1d77ab', '#2688bd', '#2e9ace', '#5ab3db', '#86cce7', '#b2e5f4'];
const peaksColours = ['#912928', '#a22d27', '#b23027', '#c33426', '#d1452f', '#df5738', '#ed6841', '#f38954', '#f9ab68', '#ffcc7b'];

// export const PRIMARY_COLOURS =
// 	process.env.NEXT_PUBLIC_SITE_VERSION === Types.VERSION.TREKKING
// 		? ['#0E4868', '#15659A', '#2E9ACE', '#B2E5F4']
// 		: ['#912928', '#C33426', '#ED6841', '#FFCC7B']
const isTrekking = process.env.NEXT_PUBLIC_SITE_VERSION === Types.VERSION.TREKKING;
export const THEME_COLOURS = (isTrekking ? trekkingColours : peaksColours) as [(string | undefined)?, (string | undefined)?, (string | undefined)?, (string | undefined)?, (string | undefined)?, (string | undefined)?, (string | undefined)?, (string | undefined)?, (string | undefined)?, (string | undefined)?];

// DO NOT CHANGE THIS - MUST STAY AT OR CLOSE TO MANTINE DEFAULT SHADE OR OTHER COLOURS GET MESSED UP
export const ACCENT_NO = 4;
export const ACCENT_COLOUR = THEME_COLOURS[ACCENT_NO];
const MyApp = ({
  Component,
  reduxStore,
  pageProps
}: Props) => {
  const persistor = persistStore(reduxStore);
  const client = useApollo();
  const version = process.env.NEXT_PUBLIC_SITE_VERSION as Types.VERSION;
  React.useEffect(() => {
    clarity.init(process?.env?.NEXT_PUBLIC_SITE_VERSION as Types.VERSION === Types.VERSION.TREKKING ? 'p9kin2s5sa' : 'ot8dzpegxn');
  }, []);
  const seo = {
    siteName: version === Types.VERSION.TREKKING ? 'Guided Trekking' : 'Guided Peaks'
  };
  const theme: MantineThemeOverride = {
    colorScheme: 'light',
    primaryShade: ACCENT_NO,
    // primaryShade: {
    // 	// @ts-ignore
    // 	light: 2,
    // 	// dark: 9,
    // },
    colors: {
      primary: THEME_COLOURS,
      brand: THEME_COLOURS
    },
    fontFamily: 'Inter, sans-serif',
    headings: {
      fontFamily: 'Geologica, sans-serif'
    },
    components: {
      Timeline: {
        defaultProps: {
          color: 'brand'
        }
      },
      Progress: {
        defaultProps: {
          color: 'brand'
        }
      },
      Loader: {
        defaultProps: {
          color: 'brand'
        }
      },
      NavLink: {
        defaultProps: {
          color: 'brand'
        }
      },
      Badge: {
        defaultProps: {
          color: 'brand'
        }
      },
      Slider: {
        defaultProps: {
          color: 'brand'
        }
      },
      Spoiler: {
        defaultProps: {
          color: 'brand'
        }
      },
      Anchor: {
        defaultProps: {
          color: 'brand'
        }
      },
      ActionIcon: {
        defaultProps: {
          color: 'brand'
        },
        styles: {
          root: {
            '&:hover': {
              color: '#fff'
            }
          }
        }
      },
      Alert: {
        styles: {
          title: {
            color: THEME_COLOURS[2]
          },
          icon: {
            color: THEME_COLOURS[2]
          }
        },
        variants: {
          light: theme => ({
            title: {
              color: 'white'
            },
            root: {
              backgroundColor: THEME_COLOURS[9]
            }
          }),
          outline: theme => ({
            title: {
              color: 'white'
            },
            root: {
              backgroundColor: '#fff'
            }
          })
        }
      },
      Blockquote: {
        styles: {
          icon: {
            color: THEME_COLOURS[2]
          }
        }
      },
      Checkbox: {
        defaultProps: {
          color: 'brand'
        }
      },
      // Label: {
      // 	styles: {
      // 		icon: {
      // 			color: THEME_COLOURS[2],
      // 		},
      // 	},
      // },
      Button: {
        defaultProps: {
          color: 'brand'
        },
        variants: {
          light: theme => ({
            inner: {
              color: 'white'
            }
          }),
          filled: theme => ({
            inner: {
              color: 'white'
            }
          })
        }
      },
      Rating: {
        defaultProps: {
          color: 'brand'
        }
      },
      Pagination: {
        defaultProps: {
          color: 'brand'
        }
      },
      Chip: {
        defaultProps: {
          color: 'brand'
        },
        variants: {
          light: theme => ({
            root: {
              color: 'white'
            }
          })
        }
      }
    }
  };
  return <main className={`${interFont.variable}`} data-sentry-component="MyApp" data-sentry-source-file="_app.tsx">
			<NextNProgress color={ACCENT_COLOUR} options={{
      showSpinner: false
    }} data-sentry-element="NextNProgress" data-sentry-source-file="_app.tsx" />
			<ApolloProvider client={client} data-sentry-element="ApolloProvider" data-sentry-source-file="_app.tsx">
				{/* 
     // @ts-ignore */}
				<Provider store={reduxStore} data-sentry-element="Provider" data-sentry-source-file="_app.tsx">
					<PersistGate loading={<Component {...pageProps} />} persistor={persistor} data-sentry-element="PersistGate" data-sentry-source-file="_app.tsx">
						{/* Your Plausible Analytics script */}
						<Script src="https://plausible.io/js/script.hash.outbound-links.pageview-props.tagged-events.js" data-domain={process?.env?.NEXT_PUBLIC_SITE_VERSION as Types.VERSION === Types.VERSION.TREKKING ? 'guidedtrekking.com' : 'guidedpeaks.com'} strategy="lazyOnload" data-sentry-element="Script" data-sentry-source-file="_app.tsx" />
						<Script id="plausible-init" strategy="lazyOnload" data-sentry-element="Script" data-sentry-source-file="_app.tsx">
							{`window.plausible = window.plausible || function() { (window.plausible.q = window.plausible.q || []).push(arguments) }`}
						</Script>

						<MantineProvider withGlobalStyles withNormalizeCSS theme={{
            ...theme,
            colorScheme: 'light',
            primaryColor: 'brand'
          }} data-sentry-element="MantineProvider" data-sentry-source-file="_app.tsx">
							{<VersionContext>
									<DefaultSeo openGraph={{
                type: 'website',
                locale: 'en_US',
                siteName: seo.siteName
              }} />
									<ModalsProvider>
										<Component {...pageProps} />
									</ModalsProvider>
									<FeedbackWidget version={process.env.NEXT_PUBLIC_SITE_VERSION as Types.VERSION} />
									<CheckToken />
									<Notifications position="top-right" />
								</VersionContext>}
						</MantineProvider>
					</PersistGate>
				</Provider>
			</ApolloProvider>
		</main>;
};

// MyApp.getInitialProps = withVersion(async (context) => {
// 	const { version } = context

// 	return {
// 		version,
// 	}
// })

export default withReduxStore(MyApp);